<template>
    <div>
        <el-row :gutter="24" v-if="this.addForm.subjects&&this.addForm.subjects.length">
            <el-col :span="12" class="left_div">
                <div>
                    <div class="subject_title flex_just_s_between">
                        <div class="shu">总分评估</div>
                        <el-button type="primary" size="small" @click="edit(form)" class="btn">编辑</el-button>
                    </div>
                    <div class="list_big_div">
                        <el-form :model="form" ref="addFormThree" label-width="100px" class="cotent_div">
                            <el-form-item label="总分公式"> {{form.factorFormula&&form.factorFormula.formulaName}}
                            </el-form-item>
                            <el-form-item label="评分等级">
                                <div class="flex evaluation_title">
                                    <div class="color_g"></div>
                                    <el-button type="primary" size="small" class="btn1"
                                        @click="editSubmit('true',form.factorId)">+ 添加测评
                                    </el-button>
                                </div>
                                <div v-for="(item,i) in form.factorSchemes" :key="i" class="list_div">
                                    <div class="flex_just_s_between" style="height:43px;">
                                        <div class="flex_align_center">
                                            <div class="color_g">{{item.scoreFrom}} - {{item.scoreTo}} 分</div>
                                            <div class="color_b">{{item.levelName}}</div>
                                        </div>
                                        <div class="text_center flex">
                                            <div>
                                                <el-button type="text" size="small"
                                                    @click="editSubmit(item,form.factorId)">修改
                                                </el-button>
                                            </div>
                                            <el-button type="text" size="small" style="color:red"
                                                @click="del(item,'schemeId')">删除
                                            </el-button>
                                        </div>
                                    </div>
                                    <div class="content_div" v-html="item.schemeDesc"></div>
                                </div>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
                <div>
                    <div class="subject_title flex_just_s_between">
                        <div class="shu">因子评估</div>
                        <el-button type="primary" size="small" @click="edit(form.factorId,'true')" class="btn">新增因子
                        </el-button>
                    </div>
                    <div class="list_big_div" v-for="(item, index) in subFactors" :key="index">
                        <el-form v-if="subFactors&&subFactors.length>0" :model="form" ref="addFormThree"
                            label-width="100px" class="cotent_div">
                            <div class="flex_just_s_between">
                                <div>因子{{index+1}}
                                    <span>{{item.factorName}}</span>
                                </div>
                                <div>
                                    <el-button type="text" size="small" @click="edit(item,'true',1)" class="btn">编辑
                                    </el-button>
                                    <el-button type="text" style="color:red" size="small" @click="del(item,index)">删除
                                    </el-button>
                                </div>
                            </div>
                            <el-form-item label="因子公式">
                                {{item.factorFormula&&item.factorFormula.formulaName }}
                            </el-form-item>
                            <el-form-item label="评分等级">
                                <div class="flex evaluation_title">
                                    <div class="color_g"></div>
                                    <el-button type="primary" size="small" class="btn1"
                                        @click="editSubmit('true',item.factorId)">+ 添加测评
                                    </el-button>
                                </div>
                                <div>
                                    <div v-for="(item,i) in subFactors[index].factorSchemes" :key="i" class="list_div">
                                        <div class="flex_just_s_between">
                                            <div class="flex_align_center">
                                                <div class="color_g">{{item.scoreFrom}} - {{item.scoreTo}} 分</div>
                                                <div class="color_b">{{item.levelName}}</div>
                                            </div>
                                            <div class="text_center flex">
                                                <el-button type="text" size="small"
                                                    @click="editSubmit(item,subFactors[index].factorId)">修改
                                                </el-button>
                                                <el-button type="text" size="small" style="color:red"
                                                    @click="del(item,'schemeId')">删除
                                                </el-button>
                                            </div>
                                        </div>
                                        <div class="content_div" v-html="item.schemeDesc"></div>
                                    </div>
                                </div>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
            </el-col>
            <el-col :span="12" class="left_div">
                <div v-if="showFactor==true&&fatForm.factorFormula">
                    <div class="subject_title flex_just_s_between">
                        <p class="shu">{{mainFlag?"新增因子":"总分评估"}}</p>
                        <el-button type="primary" size="small" @click="submit(mainFlag!='true'?1:2)" class="btn">保存
                        </el-button>
                    </div>
                    <el-form ref="fatForm" class="cotent_div">
                        <el-form-item v-if="mainFlag">
                            <div class="flex">
                                <div class="formula_lable">因子名称：</div>
                                <el-input v-model="fatForm.factorName" @input="$forceUpdate()"></el-input>
                            </div>
                        </el-form-item>
                        <el-form-item>
                            <div class="flex">
                                <div class="formula_lable">公式名称：</div>
                                <el-input v-model="fatForm.factorFormula.formulaName" @input="$forceUpdate()">
                                </el-input>
                            </div>
                        </el-form-item>
                        <el-form-item>
                            <div class="flex">
                                <div class="formula_lable">因子描述：</div>
                                <el-input v-model="fatForm.factorDesc" @input="$forceUpdate()"></el-input>
                            </div>
                        </el-form-item>
                        <div class="textarea">标准分</div>
                        <el-input type="textarea" v-model="fatForm.standardScores" :rows="4" show-word-limit
                            placeholder="标准分">
                        </el-input>
                        <div class="formula_name">
                            取整算法：<span>{{showType(fatForm.roundType)}}</span>
                        </div>
                        <el-form-item>
                            <el-radio-group v-model="fatForm.roundType">
                                <el-radio v-for="(val, i) in typeList" :key="i" :label="val.value">{{ val.label }}
                                </el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-button type="text" @click="getSampleFormula"> 点击获取公式 </el-button>
                        <el-form-item>
                            <el-input type="textarea" v-model="fatForm.factorFormula.formulaExpr"
                                @input="$forceUpdate()" :rows="4" show-word-limit placeholder="计算公式不能为空"></el-input>
                        </el-form-item>

                        <!-- <div class="btn_div">
                        使用模板：
                        <el-button type="primary" size="small" plain v-for="(item,i) in btnList" :key="i"
                            @click="getTypeFn(item.value)">
                            {{item.label}}
                        </el-button>
                    </div> -->
                        <el-form-item label="是否显示：">
                            <el-radio-group v-model="fatForm.isShow" @input="$forceUpdate()">
                                <el-radio :label="item.value" v-for="(item, index) in showList" :key="index">
                                    {{item.label}}</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="公式模式：">
                            <el-radio-group v-model="fatForm.factorFormula.formulaModel" @input="$forceUpdate()">
                                <el-radio :label="item.value" v-for="(item, index) in formulaList" :key="index">
                                    {{item.label}}</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <div class="textarea">公式配置 <el-button type="text" @click="getFormulaConfig"> (点击获取公式配置)
                            </el-button>
                        </div>
                        <el-input type="textarea" v-if="fatForm.factorFormula&&fatForm.factorFormula.formulaModel==1"
                            @input="$forceUpdate()" v-model="fatForm.factorFormula.factorGroups" :rows="4"
                            show-word-limit placeholder="公式配置">
                        </el-input>
                        <el-input v-else type="textarea" v-model="fatForm.factorFormula.qs" @input="$forceUpdate()"
                            :rows="4" show-word-limit placeholder="公式配置">
                        </el-input>
                    </el-form>
                </div>
            </el-col>
        </el-row>
        <!-- 因子结论编辑 -->
        <modal :obj="{
        title: (editFlag!='true')?'编辑':'新增',
        dialog: schemesDialog,
        dialogText: 'schemesDialog',
        form: schemesInfo,
        ref: 'schemesInfo',
        okText: '确认',
        width:'1200px',
        height:'800px'
      }" @submit="dialogSubmit">
            <el-form :model="schemesInfo" label-width="150px" ref="schemesInfo">
                <el-form-item label="评分区间：" :required="true">
                    <el-input v-model="schemesInfo.scoreFrom" maxlength="4" class="w_80" @input="inputNum1">
                    </el-input>分 -
                    <el-input v-model="schemesInfo.scoreTo" maxlength="4" class="w_80" @input="inputNum2">
                    </el-input>分
                </el-form-item>
                <el-form-item label="成绩等级：" :required="true">
                    <div class="flex">
                        <el-select v-model="schemesInfo.levelType" class="w_120">
                            <el-option v-for="(item, index) in 11" :key="index" :label="index==0?'无':index"
                                :value="index"> </el-option>
                        </el-select>
                        <div class="flex " v-show="schemesInfo.levelType>0">
                            <el-input v-model="schemesInfo.levelName" class="time_box" placeholder="等级名称"></el-input>
                            <el-checkbox-group v-model="schemesInfo.needAudit" class="time_box">
                                <el-checkbox name="type">预警</el-checkbox>
                            </el-checkbox-group>
                        </div>
                    </div>
                </el-form-item>
                <el-form-item label="插入分值：">
                    <span class="span_text cursor_pointer" @click="addTotalScore()">插入</span>
                    <div class="div_text">分值标识会自动被替换为用户测评分数</div>
                </el-form-item>
                <el-form-item label="诊断建议：" :required="true">
                    <TinyMceEditor v-if="schemesDialog" v-model="schemesInfo.schemeDesc" @change="changeTinyContent" />
                </el-form-item>
            </el-form>
        </modal>
    </div>
</template>

<script>
    import debounce from 'lodash/debounce'
    import modal from "@/components/modal";
    import TinyMceEditor from "../../../../components/TinyMceEditor"
    var Parser = require('expr-eval').Parser
    import {
        topicFactorInfo,
        topicFactorSave,
        topicFactorUpdate,
        topicFactorDel,
        deletePsy,
        schemerUpdate,
        schemerSave,
        schemeDel,
        sampleFormula,
        formulaConfig
    } from "../../../../api/api"
    import {
        Container
    } from 'element-ui';
    export default {
        props: ["addForm", "topicId"],
        components: {
            TinyMceEditor,
            modal
        },
        data() {
            return {
                fatForm: {
                    topicId: this.topicId,
                    factorName: "",
                    factorDesc: "",
                    isShow: 1,
                    parentId: 0,
                    roundType: 0,
                    standardScores: "",
                    factorSchemes: "",
                    factorFormula: {
                        factorGroups: "",
                        formulaExpr: "",
                        formulaModel: 0,
                        formulaName: "",
                        qs: "",
                    },
                },
                factorFormula: {}, //公式计算
                subFactors: [],
                schemesDialog: false,
                showFactor: false,
                schemesInfo: {
                    factorId: "",
                    scoreFrom: "",
                    scoreTo: "",
                    levelType: "",
                    levelName: "",
                    needAudit: false,
                    schemeDesc: "",
                    status: null,
                    remark: ""
                },
                typeList: [{
                        value: 0,
                        label: "向下取整",
                    },
                    {
                        value: 1,
                        label: "向上取整",
                    },
                    {
                        value: 2,
                        label: "四舍五入",
                    },
                    {
                        value: 3,
                        label: "不取整，保留2位小数",
                    },
                ],
                showList: [{
                        value: 0,
                        label: "否",
                    },
                    {
                        value: 1,
                        label: "是",
                    }
                ],
                formulaList: [{
                        value: 0,
                        label: "问题计算",
                    },
                    {
                        value: 1,
                        label: "因子计算",
                    }
                ],
                btnList: [{
                        value: "1",
                        label: "累加",
                    },
                    // {
                    //     value: "1.25",
                    //     label: "累加 × 因子1.25",
                    // },
                    {
                        value: "1.5",
                        label: "累加 × 因子1.5",
                    },
                    // {
                    //     value: "1.75",
                    //     label: "累加 × 因子1.75",
                    // },

                ],
                visible: false,
                editFlag: "",
                mainFlag: "", //子因子新增标识 
                sonFlag: "", //子因子编辑标识 1代表子因子编辑
                multiple: "",
                form: {},
            }
        },
        watch: {
            "topicId"(val) {
                if (val == "true" && this.addForm.topic) {
                    this.topicId = this.$util.superCopy(this.addForm.topic.topicId)
                }
            }
        },
        mounted() {
            this.topicId = (this.topicId != "true") ? this.topicId : this.$util.superCopy(this.addForm.topic.topicId);
            this.getQuestionsList(this.topicId);
        },

        methods: {
            changeTinyContent(val) {
                this.$forceUpdate()
                this.schemesInfo.schemeDesc = val
            },
            // 获取量表因子信息
            getQuestionsList(id, bool) {
                this.$request(topicFactorInfo, {
                    topicId: id
                }).then((res) => {
                    if (res.code == 0) {
                        let data = res.data
                        data.standardScores = JSON.stringify(data.standardScores) || "";
                        if (data.factorFormula && JSON.stringify(data.factorFormula) != '{}') {
                            data.factorFormula.factorGroups = JSON.stringify(data.factorFormula.factorGroups) ||
                                "";
                            data.factorFormula.qs = JSON.stringify(data.factorFormula.qs) || "";
                        }
                        this.subFactors = []
                        if (data.subFactors && data.subFactors.length > 0) {
                            this.subFactors = data.subFactors
                        }
                        this.form = this.$util.superCopy(data)
                        if (bool) {
                            this.fatForm = this.$util.superCopy(data)

                        }

                    }
                });
            },

            //父因子编辑
            edit(val, bool, i) {
                this.mainFlag = bool
                this.sonFlag = i
                if (this.$refs.fatForm) {
                    this.$refs.fatForm.resetFields();
                }
                this.fatForm = {
                    //  factorId: val,
                    topicId: this.topicId,
                    factorName: "",
                    factorDesc: "",
                    isShow: 1,
                    parentId: 0,
                    roundType: 0,
                    standardScores: '',
                    factorFormula: {
                        factorGroups: '',
                        qs: '',
                        formulaExpr: '',
                        formulaModel: 0,
                        formulaName: '',
                    }
                }
                this.showFactor = true
                if (this.mainFlag != 'true' || i == 1) {
                    this.fatForm = this.$util.superCopy(val)
                    if (i == 1) {
                        this.fatForm.standardScores = JSON.stringify(this.fatForm.standardScores) || "";
                        this.fatForm.factorFormula.factorGroups = JSON.stringify(this.fatForm.factorFormula
                            .factorGroups) || "";
                        this.fatForm.factorFormula.qs = JSON.stringify(this.fatForm.factorFormula.qs) || "";
                    }
                } else {
                    this.fatForm.parentId = val
                }
            },
            //新增修改 factorSchemes内容
            editSubmit(val, i) {
                console.log(val, "val")
                this.editFlag = val
                this.schemesDialog = true
                if (this.$refs.schemesInfo) {
                    this.$refs.schemesInfo.resetFields();
                }
                this.schemesInfo = {
                    scoreFrom: "",
                    scoreTo: "",
                    levelType: 0,
                    levelName: "",
                    needAudit: false,
                    schemeDesc: "",
                    status: null,
                    remark: "",
                    topicId: this.topicId
                }
                if (val != "true") {
                    this.schemesInfo = this.$util.superCopy(val)
                }
                this.schemesInfo.factorId = i

            },
            dialogSubmit: debounce(function () {
                if (!this.schemesInfo.scoreFrom) {
                    this.$util.message("err", "评分区间")
                    return
                }
                if (!this.schemesInfo.scoreTo) {
                    this.$util.message("err", "评分区间")
                    return
                }
                if (!this.schemesInfo.schemeDesc) {
                    this.$util.message("err", "请添加诊断建议")
                    return
                }
                this.$request(this.editFlag != 'true' ? schemerUpdate : schemerSave, this.schemesInfo).then((
                    res) => {
                    if (res.code == 0) {
                        this.schemesDialog = false
                        this.getQuestionsList(this.topicId)
                    }

                })
            }, 2000, {
                'leading': true,
                'trailing': false
            }),
            add(val, i) {
                if (!this.showForm.subjects.length) {
                    this.$util.message("err", "请添加量表题目")
                    return
                }
                if (!this.showForm.formula.formulaName) {
                    this.$util.message("err", "请添加测评公式")
                    return
                }
                this.edit = val
                this.editId = i
                this.form = {
                    scoreFrom: "",
                    scoreTo: "",
                    levelType: 0,
                    showNeedAudit: false,
                    needWarning: 0,
                    levelName: "",
                    schemeDesc: "",
                    topicId: this.topicId,
                    factorId: val.factorId,
                }
                if (val != "true") {
                    this.form = this.$util.superCopy(val)
                }
            },
            del(val, i) {
                let url = (i == 'schemeId') ? schemeDel : topicFactorDel
                let params1 = {
                    factorId: val.factorId ? val.factorId : ""
                }
                let params2 = {
                    schemeId: val.schemeId ? val.schemeId : ""
                }
                this.$util.isSwal("确定删除？", () => {
                    this.$request(url, (i == 'schemeId') ? params2 : params1).then((res) => {
                        if (res.code == 0) {
                            this.$util.message("suc", "操作成功");
                            this.getQuestionsList(this.topicId)
                        }
                    });
                })
            },
            //获取计算公式
            getSampleFormula() {
                let factorFormula = this.fatForm.factorFormula
                // if(!factorFormula.formulaExpr){
                //     this.$util.message("err","请先填写计算公式!")
                //     return
                // }
                let parmars = {
                    topicId: JSON.parse(this.topicId),
                    formulaExpr: factorFormula.formulaExpr,
                    formulaModel: factorFormula.formulaModel,
                    factorId: this.fatForm.factorId || ""
                }
                this.$request(sampleFormula, parmars).then((res) => {
                    if (res.code == 0) {
                        this.fatForm.factorFormula.formulaExpr = this.$util.superCopy(res.data)
                    }
                })
            },
            //获取公式配置
            getFormulaConfig() {
                if (!this.fatForm.factorFormula.formulaExpr) {
                    this.$util.message("err", "请先填写计算公式!")
                    return

                }
                let parmars = {
                    topicId: JSON.parse(this.topicId),
                    formulaExpr: this.fatForm.factorFormula.formulaExpr,
                    formulaModel: this.fatForm.factorFormula.formulaModel,
                    factorId: this.fatForm.factorId
                }
                this.$request(formulaConfig, parmars).then((res) => {
                    if (res.code == 0) {
                        let data = JSON.stringify(res.data)
                        if (parmars.formulaModel == 0) {
                            this.fatForm.factorFormula.qs = this.$util.superCopy(data)
                        } else {
                            this.fatForm.factorFormula.factorGroups = this.$util.superCopy(data)
                        }

                    }

                })

            },
            // 点击计算模板
            getTypeFn(val) {
                this.$forceUpdate()
                let bool = this.fatForm.roundType === 0
                if (!this.addForm.subjects.length) {
                    this.$util.message("err", "请先添加题目")
                    return
                }
                if (!this.fatForm.roundType && !bool) {
                    this.$util.message("err", "请选择取整算法")
                    return
                }
                // if (this.addForm.subjects)
                // this.visible = false
                let text = ""
                let length = this.addForm.subjects.length
                this.addForm.subjects.find((item, i) => {
                    if (i < length - 1) {
                        text += `T${this.showNum(i)} + `
                    } else {
                        text += `T${this.showNum(i)}`
                    }
                })
                if (val == 1) {
                    this.fatForm.factorFormula.formulaExpr = text
                } else {
                    this.fatForm.factorFormula.formulaExpr = `(${text}) * ${val}`
                }
            },

            inputNum1() {
                this.$forceUpdate()
                this.schemesInfo.scoreFrom = this.$util.isNumber2(this.schemesInfo.scoreFrom)
            },
            inputNum2() {
                this.$forceUpdate()
                this.schemesInfo.scoreTo = this.$util.isNumber2(this.schemesInfo.scoreTo)

            },
            addTotalScore() {
                tinymce.activeEditor.execCommand('mceInsertContent', false,
                    `<span style="color:red">{total_score}</span>`);
            },

            submit: debounce(function (val) {
                let bool = this.fatForm.roundType == 0
                if (!this.addForm.subjects.length) {
                    this.$util.message("err", "请先添加题目")
                    return
                }
                if (this.mainFlag && !this.fatForm.factorName) {
                    this.$util.message("err", "请填写因子名称");
                    return
                }
                if (!this.fatForm.roundType && !bool) {
                    this.$util.message("err", "请选择取整算法");
                    return
                }
                if (!this.fatForm.factorFormula.formulaExpr.trim()) {
                    this.$util.message("err", "计算公式不能为空");
                    return
                }
                let standardScores = this.fatForm.standardScores.trim()
                let factorGroups = this.fatForm.factorFormula.factorGroups
                let qs = this.fatForm.factorFormula.qs
                if (!standardScores) {
                    this.fatForm.standardScores = []
                } else {
                    try {
                        this.fatForm.standardScores = JSON.parse(this.fatForm.standardScores)
                    } catch (error) {
                        this.$util.message("err", "标准分格式不对，应为数组");
                        return
                    }
                }
                if (!factorGroups) {
                    this.fatForm.factorFormula.factorGroups = []
                } else {
                    try {
                        this.fatForm.factorFormula.factorGroups = JSON.parse(this.fatForm.factorFormula
                            .factorGroups)
                    } catch (error) {
                        this.$util.message("err", "公式配置格式不对，应为数组");
                        return
                    }
                }
                if (!qs) {
                    this.fatForm.factorFormula.qs = []
                } else {
                    try {
                        this.fatForm.factorFormula.qs = JSON.parse(this.fatForm.factorFormula.qs)
                    } catch (error) {
                        this.$util.message("err", "公式配置格式不对，应为数组");
                        return
                    }
                }
                if (val == 1) {
                    delete this.fatForm.subFactors
                    delete this.fatForm.factorSchemes

                }
                this.$request((this.mainFlag == 'true' && this.sonFlag != 1) ? topicFactorSave :
                    topicFactorUpdate, this.fatForm).then((res) => {
                    if (res.code == 0) {
                        this.$util.message("suc", "操作成功");
                        this.showFactor = false
                        this.getQuestionsList(this.topicId, 1);
                    }
                });
            }, 2000, {
                'leading': true,
                'trailing': false
            }),

            showNum(val) {
                if (val < 9) {
                    return `0${val+1}`
                }
                if (val >= 9) {
                    return val + 1
                }
            },
            showOptionType(val) {
                switch (val) {
                    case "1":
                        return "单选题";
                    case "2":
                        return "多选选题";
                    case "3":
                        return "填空题";
                    case "4":
                        return "时间（__点__分）";
                    case "5":
                        return "时长（__小时__分钟）";
                }
            },
            showType(val) {
                switch (val) {
                    case 0:
                        return "向下取整";
                    case 1:
                        return "向上取整";
                    case 2:
                        return "四舍五入";
                    case 3:
                        return "不取整，保留2位小数";

                }
            },
        }
    }
</script>

<style lang="less" scoped>
    /deep/.el-row {
        margin-left: 0px !important;
        margin-right: 0px !important;
    }

    /deep/.el-form-item__label {
        font-weight: 500;
        font-size: 16px;
    }

    .left_div {
        height: 550px;
        overflow-y: auto;
        min-height: 200px;
        font-size: 16px;

        .list_big_div {

            // max-height: 762px;
            .color_g {
                color: rgb(66, 136, 67);
                font-size: 15px;
            }

            .color_b {
                color: rgb(105, 89, 205);
                margin-left: 5px;
            }

            .flex_just_s_between {
                padding: 10px 20px;

                .text_center {
                    div {
                        padding: 0 2px;
                    }
                }


            }

            .content_div {
                padding: 10px;
                border-top: 1px solid #e8e8e8;
            }
        }

        .evaluation_title {
            display: flex;
            justify-content: space-between;

            h3 {
                color: rgb(66, 136, 67);
            }

            border-bottom: 1px solid #e8e8e8;
            padding-bottom: 5px;
        }

        .list_div {
            margin-top: 5px;
            border: 1px solid #e8e8e8;
            border-radius: 5px;
            box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .09);
        }
    }

    .btn {
        padding: 0px 7px;
    }

    .option_div {
        padding: 10px;
        margin-right: 20px;
        border-bottom: 1px solid #e8e8e8;

        span {
            color: rgb(170, 170, 170);
            font-size: 14px;
        }
    }

    .cotent_div {
        padding: 0px 10px;

        .formula_lable {
            width: 94px;
        }

        .formula_name {
            padding-bottom: 5px;
            border-bottom: 1px solid #e8e8e8;
            margin-bottom: 5px;

            span {
                color: red;
                ;
            }
        }

        .formula_yz {
            padding: 5px 0px;
            border-bottom: 1px solid #e8e8e8;
            margin-bottom: 5px;

            span {
                color: #409EFF;
                ;
            }
        }

        /deep/.el-radio {
            margin: 5px;
        }

        /deep/.el-form-item {
            margin-bottom: 10px;
        }

        .btn_div {
            display: inline-block;



            .el-button--small {
                padding: 4px 6px;
                margin-bottom: 5px;
            }

            .el-button+.el-button {
                margin-left: 0px;
            }

            .el-button {
                margin-right: 10px;
            }

            .el-button:last-child {
                margin-right: 0px;
            }
        }

        .fn_dev {
            margin-bottom: 5px;
            padding-left: 40px;
        }

        .no_option {
            color: rgb(170, 170, 170);
            padding: 5px;
        }

        .textarea {
            padding: 10px 0px;
        }

        .bt_div {
            border-top: 1px solid #e8e8e8;
        }


        /deep/.el-popper {
            .w_100 {
                margin-bottom: 5px;
            }
        }
    }

    .span_text {
        color: red;
    }

    .right_div {
        border: 1px solid #e8e8e8;
        padding: 20px;
        margin-bottom: 10px;
        border-radius: 5px;
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .09);



        .div_text {
            font-size: 12px;
            line-height: 16px;
            color: #999999;
        }
    }

    .time_box {
        margin-left: 10px;
    }
</style>
<template>
    <div>
        <div class="flex header_dev">
            <h3 class="flex_align_center">量表题目</h3>
            <div class="flex1 text_right">
                <el-button type="primary" size="small"
                    @click="add('',showForm.length >0?showForm.length-1:0)">+ 添加题目
                </el-button>
            </div>
        </div>
        <div class="content_div">
            <div v-for="(item, i) in showForm" :key="i" class="flex_just_s_between">
                <el-form disabled>
                    <div>
                        <div v-if="item.extraText" class="b_title">
                            <span></span>{{ item.extraText }}
                        </div>
                        <div class="sub_title">
                            <!-- <span>{{i+1}}、</span> -->
                            {{ item.questionDesc}}<span class="sub_span"> （{{ showOptionType(item.questionSubType) }}
                                {{showScore(item,i)}}分 序{{item.sortIndex}}）</span>
                        </div>
                        <el-form-item class="sub_item">
                            <!-- 单选 -->
                            <el-radio-group :value="false" v-if="item.questionSubType == 1">
                                <el-radio v-for="(val, i) in item.topicOptions" :key="i">{{ val.optionDesc}}
                                    <span class="sub_span">（{{val.optionScore}}分 序{{val.sortIndex}}
                                        {{val.optionAddflag?"附加填空":""}}）</span>
                                </el-radio>
                            </el-radio-group>
                            <!-- 多选 -->
                            <el-checkbox-group :value="false" v-if="item.questionSubType == 2">
                                <el-checkbox name="type" v-for="(val, i) in item.topicOptions" :key="i">
                                    {{ val.optionDesc }}<span class="sub_span">（{{val.optionScore}}分
                                        序{{val.sortIndex}}
                                        {{val.optionAddflag?"附加填空":""}}）</span>
                                </el-checkbox>
                            </el-checkbox-group>
                            <!-- 填空   -->
                            <template v-if="item.questionSubType == 3">
                                <div>
                                    <el-input class="w_80"></el-input>
                                    <span class="sub_span">（{{item.topicOptions[0].optionScore}}分）</span>
                                </div>
                            </template>
                            <!-- 时间、时长 -->
                            <el-date-picker v-if="item.questionSubType == 4||item.questionSubType == 5" type="date"
                                format="yyyy-MM-dd" value-format="yyyy-MM-dd" placeholder="请选择时间">
                            </el-date-picker>
                        </el-form-item>
                    </div>
                </el-form>
                <div>
                    <el-button type="text" size="small" @click="add(item,i)">编辑 </el-button>
                    <el-button type="text" size="small" style="color:red" @click="del(item,i)">删除 </el-button>
                </div>
            </div>
        </div>
        <!-- 新增题目 -->
        <modal :obj="{
        title:'题目设置',
        dialog:  closeDialog,
        dialogText: 'closeDialog',
		width:'1000px',
		form:form,
        ref: 'close',
		okText:'确定',
		cancelText:'取消',
      }" @submit='submitTwo'>
            <el-row :gutter="24">
                <el-form :model="form" label-width="100px" :rules="rules" ref="twoForm">
                    <el-col :span="12">
                        <div class="subject_title">
                            题目信息
                        </div>
                        <el-form-item label="题目：" prop="questionDesc">
                            <el-input type="textarea" v-model="form.questionDesc" maxlength="120" :rows="2"
                                show-word-limit>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="附加说明：" prop="extraText">
                            <el-input type="textarea" v-model="form.extraText" maxlength="120" :rows="2"
                                show-word-limit>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="题目题型：" prop="questionType">
                            <el-select v-model="form.questionType" placeholder="请选择题型" class="w_100"
                                @change="changeType(1)">
                                <el-option v-for="(item, index) in questionTypeList" :key="index" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="题目类别：" prop="questionSubType">
                            <el-select v-model="form.questionSubType" placeholder="请选择题型" class="w_100"
                                @change="changeType(2)">
                                <el-option v-for="(item, index) in form.questionType==1?typeList1:typeList2" :key="index" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="是否必答：" prop="isRequired">
                            <el-radio-group v-model="form.isRequired">
                                <el-radio v-for="(val, i) in stateList" :key="i" :label="val.value">{{ val.label }}
                                </el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="序号：" prop="sortIndex">
                            <el-input v-model="form.sortIndex" class="w_80"
                                @input="form.sortIndex=$util.isNumber0(form.sortIndex)">
                            </el-input>
                            <span class="subject_span">题目序号，题目将以此序号由小到大</span>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <div v-if="form.questionSubType!=4&&form.questionSubType!=5">
                            <div class="subject_title">答案选项</div>
                            <!-- 单选和多选 -->
                            <div v-if="form.questionType==1">
                                <div class="flex option_header">
                                    <span class="subject_span flex_align_center">选项内容不能为空</span>
                                    <div class="flex1 text_right">
                                        <el-button type="primary" size="small" @click="adds()">+ 批量添加选项</el-button>
                                        <el-button type="primary" size="small" @click="addOption()">+ 添加选项</el-button>
                                    </div>
                                </div>
                                <div class="option_big_div" v-if="form.topicOptions&&form.topicOptions.length>0">
                                    <div class="option_div flex" v-for="(item,i) in form.topicOptions"
                                        :key="i">
                                        <div class="option_border_div">
                                            <div class="flex">
                                                <div class="option flex_align_center">选项{{i+1}}：</div>
                                                <el-input v-model="item.optionDesc"></el-input>
                                            </div>
                                            <div class=" flex_just_s_between">
                                                <el-checkbox-group v-model="item.extraInput"
                                                    class="flex_align_center">
                                                    <el-checkbox label="1" name="type">附加填空</el-checkbox>
                                                </el-checkbox-group>
                                                <div>分值：<el-input v-model="item.optionScore" class="w_80"
                                                        @input="item.optionScore=$util.isNumber0(item.optionScore)">
                                                    </el-input>
                                                </div>
                                                <div>序号：<el-input v-model="item.sortIndex" class="w_80"
                                                        @input="item.sortIndex=$util.isNumber0(item.sortIndex)">
                                                    </el-input>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="option_icon">
                                            <el-button  type="danger" icon="el-icon-delete" circle
                                                @click="delOption(i)">
                                            </el-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- 填空 -->
                            <div v-else>
                                <div>分值：<el-input v-model="form.topicOptions[0].optionScore" class="w_80"
                                        @input="form.topicOptions[0].optionScore=$util.isNumber0(form.topicOptions[0].optionScore)">
                                    </el-input>分
                                </div>
                                <div class="w_80"></div>
                            </div>
                        </div>
                    </el-col>
                </el-form>
            </el-row>
        </modal>
        <!-- 批量添加选项 -->
        <modal :obj="{
        title:'批量添加选项',
        dialog:  addsDialog,
        dialogText: 'addsDialog',
		width:'1000px',
        ref: 'adds',
		okText:'确定',
		cancelText:'取消',
      }" @submit='submitAdds'>
            <el-row :gutter="24">
                <el-form :model="form" label-width="140px" :rules="rules" ref="adds">
                      <el-form-item label="添加选项：" prop="desc">
                            <el-input v-model="desc" type="textarea" :rows="10"></el-input>
                            <span class="subject_span">您可以手动复制选项粘贴在此处，一行为一个选项。</span>
                      </el-form-item>
                </el-form>
            </el-row>
        </modal>
    </div>
</template>

<script>
    import modal from "../../../../components/modal"
    import TinyMceEditor from "../../../../components/TinyMceEditor"
    import {
        gaugeQuestionsList,
        gaugeQuestionsAdd,
        gaugeQuestionsEdit,
        gaugeQuestionsDel
    } from "../../../../api/api"
    export default {
        components: {
            modal,
            TinyMceEditor
        },
        props: ["addForm", "topicId"],
        data() {
            return {
                closeDialog: false,
                addsDialog: false,
                desc:"",
                form: {
                    questionType:"",
                    questionDesc: "", //题目
                    extraText: "", //题目提示（附加说明）
                    questionSubType: "", //题型 1单选题，2多选题，3填空题，4时间（_点_分），5时长（_小时_分钟)
                    isRequired: 1, //是否必答
                    sortIndex: "", //序号
                    showBefore:{},
                    showNext:{},
                    maxNum: "0", //最高分
                    topicOptions: [{
                        questionDesc: "", //描述
                        extraInput: false, //附加填空 1 true  0 false
                        optionScore: "", //分数
                        sortIndex: 1 ,//序号
                        isRequired: 1,
                        extraText:""
                    }
                    ]
                },
                showForm: [],
                rules: {
                    questionDesc: [{
                        required: true,
                        message: "请输入题目"
                    }],
                    questionType: [{
                        required: true,
                        message: "请选择题型"
                    }],
                    questionSubType: [{
                        required: true,
                        message: "请选择题型"
                    }],
                    isRequired: [{
                        required: true,
                        message: "请选择是否必答"
                    }],
                    sortIndex: [{
                        required: true,
                        message: "请输入序号"
                    }],
                },
                questionTypeList:[{
                    value: 1,
                    label: "选择题",
                },{
                    value: 2,
                    label: "填空题",
                    },],
                typeList1: [{
                        value: 1,
                        label: "单选题",
                    },
                    {
                        value: 2,
                        label: "多选题",
                    },
                   
                ],
                typeList2: [
                    {
                        value: 3,
                        label: "填空题",
                    },
                    {
                        value: 4,
                        label: "时间（__点__分）",
                    },
                    {
                        value: 5,
                        label: "时长（__小时__分钟）",
                    },
                ],
                stateList: [{
                        value: 1,
                        label: "是",
                    },
                    {
                        value: 0,
                        label: "否",
                    },
                ],
                edit: false,
                editId: false,
            }
        },
        watch:{
            "topicId"(val){
                if(val=="true"&&this.addForm.topic.topicId){
                  this.topicId = this.$util.superCopy(this.addForm.topic.topicId)
                 
                }
            }
        },
        mounted() {
            this.topicId = (this.topicId!="true")?this.topicId:this.$util.superCopy(this.addForm.topic.topicId);
               this.getQuestionsList(this.topicId);
        },
        methods: {
            getQuestionsList(id) {
                this.$request(gaugeQuestionsList, { topicId: id }).then((res) => {
                    if (res.code == 0) {
                        let questionsList = res.data || [];
                        let data = this.$util.superCopy(this.addForm);
                        questionsList.find(item => {
                            if (!item.extraText) {
                                item.extraText = ""
                            }
                            item.isRequired = Number(item.isRequired)
                            item.topicOptions.find(k => {
                                k.extraInput = k.extraInput == 1 ? true : false
                                 k.extraText ?  k.extraText : ""
                            })
                        });
                        data.subjects = questionsList;
                        this.$store.commit("setParam", {
                            gaugeAddForm: data
                        });
                        this.showForm = this.$util.superCopy(res.data);
                    }
                });
            },
            // 添加题目
            add(val, i) {
                  if(this.topicId=="true"){
                      this.$util.message("err","请先填写量表信息!")
                      return
                  }
                let form = {
                    questionDesc: "",
                    extraText: "",
                    questionType:1,
                    questionSubType: "",
                    isRequired: 1,
                    sortIndex: this.showForm.length+1 ,
                    showBefore:{},
                    showNext:{},
                    topicOptions: [
                    //     {
                    //     optionDesc: "",
                    //     extraInput: false,
                    //     optionScore: "",
                    //     sortIndex: 1,
                    //     isRequired: 1,
                    //     extraText:"",
                        // topicId: this.topicId
                    // }
                    ],
                    topicId: this.topicId
                }
                this.closeDialog = true;
                this.edit = val;
                this.editId = i;
                if (val) {
                    this.form = this.$util.superCopy(val);
                } else {
                    this.form = this.$util.superCopy(form);
                    if (this.$refs.twoForm) {
                        this.$refs.twoForm.resetFields();
                    }
                }
            },
            // 删除题目
            del(val, i) {
                this.$util.isSwal("确定删除？", () => {
                        this.$request(gaugeQuestionsDel, {questionId: val.questionId}).then((res) => {
                            if (res.code == 0) {
                                this.$util.message("suc", "操作成功");
                                this.getQuestionsList(this.topicId)
                            }
                        });
                    
                })
            },
            // 添加选项
            addOption() {
                this.form.topicOptions.push({
                    optionDesc: "",
                    isRequired:"",
                    extraInput: false,
                    optionScore: "",
                    extraText:"",
                    sortIndex: this.form.topicOptions.length + 1,
                    topicId: this.topicId
                })
            },
            //批量添加
            adds(){
                this.addsDialog = true
            },
            //批量添加提交
            submitAdds(){
                if(this.desc){
                  this.form.topicOptions = []
                  let arr = this.desc.split("\n")
                  arr.forEach((val)=>{
                    this.form.topicOptions.push({
                    optionDesc: val,
                    isRequired:"",
                    extraInput: false,
                    optionScore: "",
                    extraText:"",
                    sortIndex: this.form.topicOptions.length + 1,
                    topicId: this.topicId
                    })
                  })
                }
                this.addsDialog = false
                
            },
            // 删除选项
            delOption(i) {
                this.form.topicOptions.splice(i, 1);
            },
            submitTwo() {
                this.$refs.twoForm.validate((result) => {
                    if (result) {
                        let err = false;
                        let form = this.$util.superCopy(this.form)
                        if(!form.topicOptions.length>0){
                           this.$util.message("err", "请添加题目选项");  
                           return
                        }
                        if (this.form.questionSubType == 1 || this.form.questionSubType == 2) {
                            this.form.topicOptions.find((item, i) => {
                                if (err) return;
                                if (!item.optionDesc) {
                                    this.$util.message("err", `选项${i+1}内容不能为空`);
                                    err = true;
                                    return;
                                }
                                if (!item.optionScore && item.optionScore !== 0) {
                                    this.$util.message("err", `选项${i+1}分值不能为空`);
                                    err = true;
                                    return;
                                }
                                if (!item.sortIndex) {
                                    this.$util.message("err", `选项${i+1}序号不能为空`);
                                    err = true;
                                    return;
                                }
                            })
                        }
                        if (this.form.questionSubType == 3) {
                            if (!this.form.topicOptions[0].optionScore.length) {
                                this.$util.message("err", "分数不能为空");
                                return;
                            }
                        }
                        if (err) return;
                        this.form.topicOptions.find((item, i) => {
                            item.extraInput = item.extraInput ? 1 : 0
                        })
                        form = this.$util.superCopy(this.form)
                        // if (this.topicId == "true") {
                        //     this.closeDialog = false;
                            // if (this.edit) {
                            //     this.showForm.subjects[this.editId] = this.$util.superCopy(form);
                            // } else {
                            //     this.showForm.subjects.push(form);
                            // }
                            // this.$store.commit("setParam", {
                            //     gaugeAddForm: this.showForm
                            // });
                        // } else {
                            let url = this.edit ? gaugeQuestionsEdit : gaugeQuestionsAdd;
                            // form.subjectMainid = this.showForm.subjects[0].subjectMainid
                            this.$request(url,form).then((res) => {
                                if (res.code == 0) {
                                    this.closeDialog = false;
                                    this.$util.message("suc", "操作成功");
                                    this.getQuestionsList(this.topicId);
                                }
                            });
                        // }
                    }
                })
            },
            // 改变题型
            changeType(val) {
                 console.log(val,"dddd")
                 if(val==1){
                     this.form.questionSubType = ""   
                    }
                if (this.form.questionSubType != 1 && this.form.questionSubType != 2) {
                    this.form.topicOptions = [{
                        optionDesc: "",
                        extraInput: false,
                        optionScore: "",
                        sortIndex: 1
                    }];
                }
            },
            showOptionType(val) {
                switch (val) {
                    case 1:
                        return "单选题";
                    case 2:
                        return "多选题";
                    case 3:
                        return "填空题";
                    case 4:
                        return "时间（__点__分）";
                    case 5:
                        return "时长（__小时__分钟）";
                }
            },
            showScore(val, i) {
                let num
                if (val.questionSubType == 1 || val.questionSubType == 2) {
                    let arr = val.topicOptions.map(item => {
                        return item.optionScore;
                    })
                    num = Math.max(...arr);
                }
                if (val.questionSubType == 3) {
                    num = val.topicOptions[0].optionScore;
                }
                if (val.questionSubType == 4 || val.questionSubType == 5) {
                    num = 1;
                }
                this.showForm[i].maxNum = num;
                return num;
            }
        }
    }
</script>

<style lang="less" scoped>
    .header_dev {
        border: 1px solid #e8e8e8;
        padding: 10px;
    }

    .content_div {
        min-height: 200px;
        border-width: 1px;
        border-style: solid;
        border-color: transparent #e8e8e8 #e8e8e8 #e8e8e8;
        padding: 40px 40px 5px;
        height: calc(100vh - 490px);
        min-height: 200px;
        overflow-y: auto;

        .flex_just_s_between {
            border-bottom: 1px solid #e8e8e8;
            margin-bottom: 15px;
        }

        .flex_just_s_between:last-child {
            border-bottom: none
        }

        .b_title {
            font-size: 18px;
            color: #409eff;
        }

        .sub_title,
        /deep/.el-radio__input.is-disabled+span.el-radio__label,
        /deep/.el-checkbox__input.is-disabled+span.el-checkbox__label {
            color: #000000d9;
        }

        .sub_span {
            color: #00000040;
            margin-left: 8px;
        }

        .sub_item {
            margin-top: 8px;
        }

        /deep/.el-form-item {
            margin-bottom: 10px;
        }

        /deep/.el-radio {
            display: block;
            margin-bottom: 10px;
        }

        .el-checkbox {
            display: block;
        }

        /deep/.el-form-item__content {
            line-height: 25px;
        }
    }

    .subject_span {
        color: rgb(170, 170, 170);
        font-size: 14px;
    }

    .option_header {
        padding-bottom: 5px;
        margin-bottom: 10px;
        border-bottom: 1px solid rgb(204, 204, 204);

        .subject_span {
            padding-left: 10px;
        }
    }

    .option_big_div {
        max-height: 400px;
        overflow-y: auto;

        .option_div {
            margin-bottom: 5px;


            .option_border_div {
                width: 370px;
                padding: 5px;
                border: 1px solid rgb(204, 204, 204);
                border-radius: 5px;

                .flex {
                    margin-bottom: 5px;

                    .option {
                        min-width: 60px;
                    }
                }
            }

            .option_icon {
                width: 40px;
                margin: 20px 0px 0px 10px;
            }
        }
    }

    /deep/.el-textarea__inner {
        padding: 5px 48px 5px 15px;
    }
</style>
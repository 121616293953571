<template>
  <div>
    <editor :init="editorInit" v-model="contentHtml" :disabled="disabled" :autoresize="autoresize" />
    <loading v-if="loading" />
  </div>
</template>
<script>
  import Editor from "@tinymce/tinymce-vue";
  // import 'tinymce/plugins/media'
  // 后端上传接口,按照需求而定
  import {
    files,
  } from "../api/api";
  import request from "../api/request";
  import axios from 'axios'
  import store from '../store/index'
  import util from '../Util'
  import loading from "./loading"
  // 上传本地库
  const localUpload = (blobInfo, success, failure) => {
    const formData = new FormData();
    formData.append("file", blobInfo.blob());
    request(files(1), formData)
      .then((res) => {
        if (res.code === 0) {
          let file = res.data.url;
          success(file);
          return;
        }
        failure("上传失败");
      })
      .catch(() => {
        failure("上传出错");
      });
  }
  export default {
    name: "TinyMceEditor",
    model: {
      prop: "content",
      event: "change",
    },
    props: {
      // 绑定的文本内容
      content: {
        type: String,
        default: "",
      },
      // 是否禁止输入
      disabled: {
        type: Boolean,
        default: false,
      },
      // true则自适应高
      autoresize: {
        type: Boolean,
        default: false,
      },
    },
    components: {
      Editor,
      loading
    },

    data: () => ({
      // 编辑器配置
      editorInit: {
        language_url: "/zh_CN.js", //汉化文件的路径, 这里的原始路径是'/public/zh_CN.js'
        language: "zh_CN",

        height: 500,
        menubar: true,
        plugins: [
          "advlist autolink lists link image charmap print preview anchor",
          "searchreplace visualblocks code fullscreen",
          "insertdatetime media table paste code help wordcount",
          "image media",
        ],
        toolbar: "undo redo | formatselect | bold italic underline strikethrough superscript subscript codeformat forecolor backcolor removeformat| \
           alignleft aligncenter alignright alignjustify  outdent indent lineheight| \
           bullist numlist  | link image media template codesample inserttable table    | \
           charmap emoticons hr insertdatetime | cut copy newdocument print | \
           spellchecker |searchreplace preview fullscreen | help | ",
        file_picker_types: ' media',
        content_css: 'css/content.css',
        file_picker_callback: function (cb, value, meta) {
          if (meta.filetype == 'media') {
            //创建一个隐藏的type=file的文件选择input
            let input = document.createElement('input');
            input.setAttribute('type', 'file');
            input.onchange = function (e) {
              let file = e.target.files[0]; //只选取第一个文件。如果要选取全部，后面注意做修改
              store.commit('setParam', {
                loading: true
              })
              const formData = new FormData();
              formData.append("file", file);
              request(files(2), formData).then((res) => {
                  store.commit('setParam', {
                    loading: false
                  })
                  if (res.code === 0) {
                    let fileUrl = res.data.url;
                    cb(fileUrl, {
                      title: file.name
                    });
                    return;
                  } else {
                    util.message("err", "上传失败")
                  }
                })
                .catch(() => {
                  util.message("err", "上传失败");
                  store.commit('setParam', {
                    loading: false
                  })
                });
            }
            //触发点击
            input.click();

          }
        },
        //自定义逻辑替换 Tinymce 的默认媒体嵌入逻辑
        media_url_resolver: function (data, resolve) {
          try {
            let videoUri = encodeURI(data.url);
            let embedHtml
            if (videoUri) {
              embedHtml = `<p>
                    <span
                        class="mce-object mce-object-video"
                        data-mce-selected="1"
                        data-mce-object="video"
                        data-mce-p-width="100%"
                        data-mce-p-height="auto"
                        data-mce-p-controls="controls"
                        data-mce-p-controlslist="nodownload"
                        data-mce-p-allowfullscreen="true"
                        data-mce-p-src=${videoUri} >
                        <video src=${data.url} width="100%" height="auto" controls="controls" controlslist="nodownload">
                        </video>
                    </span>
                </p>
                <p style="text-align: left;"></p>`;
            } else {
              embedHtml = ""
            }
            resolve({
              html: embedHtml
            });
          } catch (e) {
            resolve({
              html: ""
            });
          }
        },

        branding: false,

        // 自定义图片上传
        images_upload_handler: (blobInfo, success, failure) => {
          localUpload(blobInfo, success, failure)
        },

      },

      contentHtml: "",
      updateTimer: null, //防抖
    }),
    computed: {
      loading() {
        return store.state.loading
      }
    },
    watch: {
      content(valueFromParent) {
        // 由父组件自动修改的内容,同步到子组件
        if (valueFromParent !== this.contentHtml) {
          this.contentHtml = valueFromParent;
        }
      },

      contentHtml(valueFromChild = "") {
        // 向父组件派发change事件
        if (valueFromChild !== this.content) {
          this.$emit("change", valueFromChild);
        }
      },
    },
    mounted() {
      if (this.content !== this.contentHtml) {
        this.contentHtml = this.content;
      }
      if (this.autoresize) {
        this.editorInit.plugins.push("autoresize")
        this.editorInit.toolbar = "undo redo | formatselect | bold italic underline strikethrough superscript subscript | codeformat forecolor backcolor removeformat| \
           alignleft aligncenter alignright alignjustify  outdent indent lineheight| \
           bullist numlist  | template codesample inserttable table    | \
           charmap emoticons hr insertdatetime | cut copy newdocument print | \
           spellchecker | preview fullscreen | | help | "
      };
      store.commit('setParam', {
        loading: false
      })
    },
    beforeDestroy() {
      this.updateTimer && clearTimeout(this.updateTimer);
    },
  };
</script>
<style lang="less">
  .tox-tinymce {
    border-radius: 5px !important;
  }

  /deep/.tox-tinymce-aux {
    z-index: 2001 !important;
  }
</style>
<template>
    <div>
        <el-row :gutter="24">
            <el-col :xs="24" :md="20">
                <el-form :model="form" label-width="150px" :rules="rules" ref="addFormOne">
                    <el-form-item label="量表名称：" prop="topicName">
                        <el-input v-model="form.topicName"></el-input>
                    </el-form-item>
                    <el-form-item label="量表图标：" prop="iconUrl">
                        <div class="flex">
                            <el-upload :action="action" :show-file-list="false" :on-success="handleAvatarSuccess"
                                accept=".png,.jpg,.jpeg,.bmp,.webp" :headers="headers">
                                <el-button size="small" type="primary">点击上传</el-button>
                            </el-upload><span class="upload_span_text">请使用JPG / PNG / BMP格式，比例为1:1</span>
                        </div>
                        <div>
                            <img v-if="form.iconUrl" :src="form.iconUrl" class="w_250" />
                        </div>
                    </el-form-item>
                    <el-form-item label="测评须知：" prop="subDesc">
                        <el-input id="input" v-model="form.subDesc" @input="changeContent" type="textarea" placeholder="请输入测评须知" :rows="4" />
                    </el-form-item>
                    <el-form-item label="量表简介：" prop="topicDesc">
                        <TinyMceEditor v-model="form.topicDesc" @input="changeContent" @change="changeTinyContent" />
                    </el-form-item>
                    <!-- <el-form-item label="是否公开：" prop="isPublic">
                        <el-radio-group v-model="form.isPublic">
                            <el-radio v-for="(val, i) in stateList" :key="i" :label="val.value">{{ val.label }}
                            </el-radio>
                        </el-radio-group>
                    </el-form-item> -->
                     <el-form-item label="量表图形：" prop="topicChart">
                        <el-select class="w_100" v-model="form.topicChart" placeholder="请选择量表图形" >
                            <el-option v-for="(item, index) in chartList" :key="index" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                     <el-form-item label="量表测评计算类：" prop="factorSchemeClass">
                         <el-select class="w_100" v-model="form.factorSchemeClass" placeholder="请选择量表测评计算类" >
                            <el-option v-for="(item, index) in scoreClassList" :key="index" :label="item.dictLabel" @change="changeType" :value="item.dictValue">
                            </el-option>
                        </el-select>
                    </el-form-item>
                     <el-form-item label="所属板块：" prop="adaptorType">
                        <el-select class="w_100" v-model="form.adaptorType" placeholder="请选择所属板块" @change="getTopicTypes">
                            <el-option v-for="(item, index) in blackList" :key="index" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="量表分类：" prop="topicType" v-if="form.adaptorType">
                        <el-radio-group v-model="form.topicType" class="radio_style">
                            <el-radio v-for="(val, i) in typeList" :key="i" :label="val.typeId" @change="changeType">{{ val.typeName }}
                            </el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" size="small" @click="submit">保存</el-button>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    import TinyMceEditor from "../../../../components/TinyMceEditor"
    import qiniuyun from "../../../../components/qiniuyun"
    import ossUpload from "../../../../components/ossUpload"
    import {
        files1,
        topicTypes,
        gaugeInfo,
        gaugeEdit,
        gaugeSave,
        courseType
    } from "../../../../api/api"
    export default {
        components: {
            TinyMceEditor,
            qiniuyun,
            ossUpload
        },
        props: ["addForm", "topicId"],
        data() {
            return {
                blackList:[{
                    id:3,
                    name:'学生量表'
                    },{
                    id:4,
                    name:'家长量表'
                }],
                chartList:[{
                    id:0,
                    name:'无'
                    },{
                    id:1,
                    name:'线性图'
                },{
                    id:2,
                    name:'柱状图'
                }],
                rules: {
                    topicName: [{
                        required: true,
                        message: "请输入量表名称"
                    }],
                    topicDesc: [{
                        required: true,
                        message: "请输入量表简介"
                    }],
                    subDesc: [{
                        required: true,
                        message: "请输入测评须知"
                    }],
                    adaptorType: [{
                        required: true,
                        message: "请选择所属板块"
                    }],
                    topicChart: [{
                        required: true,
                        message: "请选择量表图形"
                    }],
                    factorSchemeClass: [{
                        required: true,
                        message: "输入量表测评计算类"
                    }],
                    topicType: [{
                        required: true,
                        message: "请选择量表分类",
                    }]
                },
                action: files1.url,
                headers: {
                    Accept: "application/json, text/plain, */*",
                    Authorization: this.$util.getToken("access_token"),
                },
                stateList: [{
                        value: 1,
                        label: "公开",
                    },
                    {
                        value: 0,
                        label: "不公开",
                    },
                ],
                typeList: [],
                scoreClassList:[],
                id:"",
                form: {
                    topicId: "",
                    topicName: "",
                    iconUrl: "",
                    topicDesc: "",
                    subDesc: "",
                    isPublic:0,
                    topicType: "",
                    psyType: 4,
                    psyTypeName: "",
                    status: 0,
                    remark: "",
                    topicChart: "",
                    factorSchemeClass:"",
                    adaptorType: "" 
                },
                copyForm: {}
            }
        },
        computed: {
        },
        watch:{
            "form.factorSchemeClass"(val){
                if(JSON.stringify(val)=='{}'){
                    this.form.factorSchemeClass = ""
                }
            }
        },
        created() {
         
            this.copyForm = this.$util.superCopy(this.addForm);
            
            
        },
        mounted() {
            this.getScoreClass()
            if (this.topicId != "true") {
                this.getInfo(this.topicId);
            }
            if (this.$refs.addFormOne) {
                this.$refs.addFormOne.resetFields();
            }
            this.form = this.copyForm.topic;
            if(this.form.topicId){
                 this.getInfo(this.form.topicId)
            }
        },
        methods: {
            changeTinyContent(val) {
                this.form.topicDesc = val
                this.changeType()
            },
            // 获取量表测评计算类
            getScoreClass() {
                this.$request(courseType,{dictType:"topic_score_class"}).then((res) => {
                    if (res.code == 0) {
                        this.scoreClassList = res.data
                    }
                });
            },
             // 获取量表分类
            getTopicTypes(e,bool) {
                if(!bool){
                    this.form.topicType = ""
                }
                this.$request(topicTypes,{typeTheme:4,adaptorType:e}).then((res) => {
                    if (res.code == 0) {
                        this.typeList = res.data
                    }
                });
            },
            // 获取量表信息
            getInfo(id) {
                this.$request(gaugeInfo, {topicId: id}).then((res) => {
                    if (res.code == 0) {
                        let data1 = { topic:res.data }
                        this.$store.commit("setParam", {
                            gaugeAddForm: data1
                        });
                        this.form = res.data
                        this.id = this.$util.superCopy(res.data.topicId)
                        this.getTopicTypes(this.form.adaptorType,1)
                       
                    }
                });
            },
            changeType(){
                 this.$forceUpdate()
                this.$refs.addFormOne.validate((result) => {
                    return true
                    })
                   
            },
            // 上传图标
            handleAvatarSuccess(res, file) {
                if (res.code == 0) {
                    let data = res.data
                    this.$util.message("suc", "上传成功");
                    data = res.data 
                    this.form.iconUrl = data.url;
                    this.changeType()
                    
                } else {
                    this.$util.message("err", res.msg);
                }
            },
            changeContent() {
                this.$refs.addFormOne.validate((result) => {
                    return true
                })
            },
            submit() {
                this.$refs.addFormOne.validate((result) => {
                    if (result) {
                            if(this.id&&this.topicId == "true"){
                              this.topicId = this.id  
                            }
                            this.$request(this.topicId == "true"?gaugeSave:gaugeEdit, this.form ).then((res) => {
                                if (res.code == 0&&res.data!=false) {
                                    this.$util.message("suc", "操作成功,请进入下一步并添加量表题目!");
                                    this.$emit("activeChange",1)
                                    if(this.topicId != "true"){
                                        this.getInfo(this.topicId);

                                    }else{
                                        this.getInfo(res.data); 
                                    }
                                
                                }
                            });
                    }
                })

            }
        }
    }
</script>

<style lang="less" scoped>
    /deep/.el-row {
        margin-left: 0px !important;
        margin-right: 0px !important;
    }

    .radio_style {
        .el-radio {
            margin-top: 10px;
        }
    }
</style>
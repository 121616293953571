<template>
    <div class=" center_div">
        <div class=" center">
            <div :class="addForm.topic&&addForm.topic.topicName?'':'red'"><i
                    :class="addForm.topic&&addForm.topic.topicName?'el-icon-success green':'el-icon-error red'"></i>
                量表名称为：{{addForm.topic.topicName||'空'}}</div>
            <div :class="addForm.subjects&&addForm.subjects.length?'':'red'"><i
                    :class="addForm.subjects&&addForm.subjects.length?'el-icon-success green':'el-icon-error red'"></i>
                量表题目数为：{{addForm.subjects.length||'0'}}</div>
            <!-- <div :class="addForm.test.formula.formulaName?'':'red'"><i
                    :class="addForm.formula.formulaName?'el-icon-success green':'el-icon-error red'"></i> 评分公式为：
                {{addForm.formula.formulaName||'空'}}</div>
            <div :class="addForm.schemes.length?'':'red'"><i
                    :class="addForm.schemes.length?'el-icon-success green':'el-icon-error red'"></i>
                评测标准数目为：{{addForm.schemes.length||'0'}}</div> -->
        </div>
    </div>
</template>

<script>
    import {
        gaugeAdd
    } from "../../../../api/api"
    import {
        mixins
    } from "../mixins"
    export default {
        props: ["addForm"],
        mixins: [mixins],
        data() {
            return {}
        },
        computed: {
            previousForm() {
                return this.$store.state.previousForm;
            },
        },
        methods: {
            submit() {
                this.$request(gaugeAdd, this.addForm).then((res) => {
                    if (res.code == 200) {
                        this.cancel()
                    }
                });
            },
            cancel() {
                this.$router.push({
                    name: "gauge",
                    params: {
                        form: this.previousForm
                    }
                });
                this.$store.commit("setParam", {
                    gaugeAddForm: this.obj
                });
            }
        }
    }
</script>

<style lang="less" scoped>
    .center_div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 50px;

        .center {
            display: flex;
            flex-direction: column;
            justify-content: center;

            div {
                padding-bottom: 10px;
                font-size: 18px;
            }

            .red {
                color: red;
            }

            .green {
                color: #52C41A;
            }

            .btn {
                margin-top: 20px;
                text-align: center;
            }
        }
    }
</style>
<template>
    <div class="gaugeAdd">
        <div class="title">
            <span>量表管理 / </span> <span>量表 / </span>
            <span class="black">{{addEdit=="true"?"新增":"编辑"}}</span>
        </div>
        <el-card>
            <el-steps :space="400" :active="active" finish-status="success" align-center>
                <el-step v-for="(item,key) in stepList" :key="key" :title="item" @click.native="clickStep(key)">
                </el-step>
            </el-steps>
            <div class="com_style">
                <component :is="currentTabComponent" :addForm="gaugeAddForm" :topicId="addEdit" @activeChange="clickStep"></component>
            </div>
            <div class="btn_div">
                <el-button type="primary" size="small" @click="clickStep(active-1)" :disabled="active==0">上一步
                </el-button>
                <el-button type="primary" size="small" @click="clickStep(active+1)"
                    :disabled="active==3||(active==2&&this.addEdit != 'true')">下一步
                </el-button>
            </div>
        </el-card>
    </div>
</template>

<script>
    import one from "./component/one"
    import two from "./component/two"
    import three from "./component/three"
    import four from "./component/four"
    export default {
        components: {
            com0: one,
            com1: two,
            com2: three,
            com3: four,
        },
        data() {
            return {
                active: 0,
                stepList: ["量表信息", "量表题目", "测分标准", "完成"],
                addEdit: null,
            }
        },
        computed: {
            currentTabComponent() {
                return "com" + this.active
            },
            gaugeAddForm() {
                return this.$store.state.gaugeAddForm
            }
        },
        created() {
            if (this.$route.params.form) {
                this.$store.commit("setParam", {
                    previousForm: this.$route.params.form
                });
            }
            this.addEdit = this.$route.params.id
            if (this.addEdit != "true") {
                this.stepList.pop()
            }
        },
        methods: {
            clickStep(val) {
                this.active = val
            },
        }
    }
</script>

<style lang="less" scoped>
    .gaugeAdd {
        .com_style {
            margin-top: 20px;
            min-height: 200px;
            height: calc(100vh - 366px);
            overflow-y: auto;
        }

        .btn_div {
            border-top: 1px solid rgb(204, 204, 204);
            text-align: center;
            margin: 10px;
            padding-top: 10px;
        }

        /deep/.el-step__head.is-process,
        /deep/.el-step__head.is-success {
            color: #1890ff;
            border-color: #1890ff;

        }

        /deep/.el-step__title.is-process,
        /deep/.el-step__title.is-success {
            color: #1890ff;
        }

        /deep/.is-success {
            .el-step__line {
                background-color: #1890ff;
            }
        }
    }
</style>
<template>
    <div class="markbox">
        <div class="sun-loading">
            <i class="el-icon-loading"></i>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {}
        }
    }
</script>

<style lang="less" scoped>
    .markbox {
        background-color: #ffffff8a;
        bottom: 0;
        left: 0;
        position: fixed;
        right: 0;
        top: 0;
        z-index: 10002;
        width: 100vw;
        height: 100vh;

        .sun-loading {
            width: 45px;
            height: 45px;
            font-size: 45px;
            display: block;
            margin: auto;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
        }
    }
</style>